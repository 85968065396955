@import '../../../styles/index.scss';

.drawer-targeting-table {
  .targeting-dropdowns {
    max-width: 473px;
    margin-bottom: grid-px(4);

    button {
      padding: grid-px(1.5) grid-px(2.25);
      min-width: 136px;
    }
  }

  .targeting-filter {
    border: 1px solid $color-gray-3;
    border-radius: grid-px(.5);
    margin-bottom: grid-px(1.5);
  }

  &__subheader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__publisher {
    font-weight: 600;
    margin-bottom: grid-px(1.5);
  }

  &__country {
    font-weight: 600;
    margin-bottom: grid-px(2);
  }
}
