@import '../../../styles/index.scss';

.targeting {
  & .targeting-dropdowns {
    padding: grid-px(3) grid-px(2);
  }

  &__targeting-filter {
    border: 1px solid $color-gray-3;
    border-radius: grid-px(.5);
    margin-bottom: grid-px(1.5);
  }

  & .card__content {
    padding: grid-px();
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__publisher {
    font-weight: 600;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__country {
    font-weight: 600;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__view {
    margin-top: 10px;
    padding-right:16px
  }

  &__placeholder-text {
    padding-left: 16px;
    margin-top: 10px;
  }
}
