@import "../../../styles/index.scss";

.duplicate-ads-tabs {
  overflow-x: scroll;

  &__content-wrapper {
    padding: grid-px(3);
  }

  &__tabs-wrapper.TrekTabNavigation-root {
    display: flex;
    position: relative;
    padding: 0;

    &::after {
      z-index: 10;
    }

    .TrekTabNavigationTab:last-child {
      border-top-right-radius: 0;
    }

    .TrekTabNavigationTab:first-child {
      border-top-left-radius: 0;
    }

    .TrekTabNavigationTab {
      border-bottom: 1px solid $color-gray-3;
      z-index: 11;

      &--selected {
        border-bottom: 1px solid $color-white;
      }
    }

    .tooltip__wrapper {
      height: 1.25rem;
    }

    .todo-item {
      margin-bottom: 0;

      &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 220px;
        height: 1.25rem;
      }

      &__check-wrapper {
        height: 1.25rem;
      }
    }
  }

  &__green-icon {
    path {
      fill: $color-green-dark;
    }
  }

  &__icon-tooltip {
    width: 22px;
    height: 20px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}
