@import "./colors.scss";
@import "./fonts.scss";
@import "./z-indices";
@import "./base.scss";
@import "./constants.scss";
@import "./mixins.scss";

$dark-border: 1px solid $border-color-dark;
$light-border: 1px solid $border-color-light;
$box-shadow-dark: 0 2px 4px 0 $box-shadow-color-dark;
$box-shadow-light: 0 2px 4px 0 $box-shadow-color-light;
$box-shadow-segmented-control:
  0 3px 1px 0 rgba(0, 0, 0, 0.04),
  0 3px 8px 0 rgba(0, 0, 0, 0.12);
