// global styles
@import '../../../styles/index.scss';

// margins
$trafficking-tab-margins: 2px;

// paddings
$trafficking-page-padding-top: grid-px(3);
$trafficking-page-padding-bottom: grid-px(3);
$trafficking-page-padding-sides: grid-px(3);
$trafficking-tab-left-padding: grid-px(3);
$trafficking-tab-right-padding: 12px;
$trafficking-tab-vertical-padding: 21px;
$trafficking-tab-horizontal-padding: grid-px(3);

// heights
$trafficking-filter-height: grid-px(11) + 4px;
$trafficking-tabs-height: grid-px(7);
$trafficking-action-bar-height: grid-px(8);
$trafficking-page-static-elements-height: (
  $header-height + $trafficking-page-padding-top + $trafficking-page-padding-bottom + $trafficking-filter-height +
    $trafficking-tabs-height + $footer-height
);

$trafficking-table-row-height: grid-px(6);
$trafficking-table-row-height-5x: $trafficking-table-row-height * 5;
$trafficking-table-header-height: 45px; // do not have clean grid-px() way to reach this value

$trafficking-table-content-height: calc(100vh - #{$trafficking-page-static-elements-height});
$trafficking-table-content-min-height: (
  $trafficking-action-bar-height + $trafficking-table-header-height + $trafficking-table-row-height-5x
);

$trafficking-page-min-height: $trafficking-page-static-elements-height + $trafficking-table-content-min-height;

// widths
$trafficking-tab-width: grid-px(45);
$trafficking-tab-nav-width: (($trafficking-tab-width * 3) - 2px);
