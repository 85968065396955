@import "../../../styles/index.scss";

.duplicate-ad-button {
  @include common-table-header-link;

  .TrekButton-label {
    .button__text {
      text-transform: none;
    }
  }
}
