@import '../../pages/Trafficking/styles/index';
@import '../../styles/colors';

$sticky-filter-drop-shadow: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));

.trafficking-table {
  flex-grow: 1;
  overflow: auto;
  z-index: 10;

  &:after,
  &:before {
    content: '';
    height: 100%;
    // relative to the trafficking page's currently selected <Tab/> component
    position: absolute;
    top: $trafficking-action-bar-height;
    width: grid-px(1.875);
    z-index: $trafficking-table-header-z-index + 3;
    pointer-events: none;
  }

  .trafficking-table__table {
    // table-wide styles that allow individual cells (both <th> and <td>) to define their borders
    // allows sticky header cells to lift their borders with them
    background-color: $white-background;
    border-collapse: separate;
    border-spacing: 0;
    counter-reset: row-number;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    width: 100%;

    // table bottom border that is only visible if last table row ends before min-height
    &:after {
      bottom: -2px;
      width: 100%;
      content: '';
      position: absolute;
      border-bottom: 1px solid $color-gray-3;
    }

    &--invisible:after {
      display: none;
    }

    .controlled-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: grid-px(5);

      input {
        display: inline-block;
        margin: 0;
      }

      &__label-content {
        color: $color-gray-1;
        font-size: $font-size-small;
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: $trafficking-table-header-z-index + 2;

      th {
        height: 47px;
      }

      tr {
        background: $color-white;
        // position and z-index here are to place the row above body cells that are also have
        // "position: relative" when scrolling along with the "stickied" <th /> elements
        // overflow: hidden - don't use it here because it breaks inside's position: sticky for resizing columns
        // https://github.com/w3c/csswg-drafts/issues/865
        position: relative;
        z-index: $trafficking-table-header-z-index + 1;
      }

      th[role='columnheader'] {
        background: $color-white;
        border: 0;
        border-top: 1px solid $color-gray-3;
        border-bottom: 1px solid $color-gray-4;
        color: $color-gray-1;
        font-size: $font-size-standard;
        font-weight: 500;
        line-height: 2.5;
        outline: none;
        position: sticky;
        text-align: left;
        top: 0;
        white-space: nowrap;
        z-index: $trafficking-table-header-z-index;

        //  Need to hide overflowing tests in thead
        &:nth-last-child(-n + 3) {
          overflow: hidden;
        }
      }

      th.sticky-cell {
        @include sticky-cell;

        border-right: none !important;
      }

      .controlled-checkbox {
        margin-bottom: grid-px(1.25);
      }
    }

    tbody {
      tr[role='row'] {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-gray-5;

        .td__off-hover {
          display: block;
        }

        .td__on-hover {
          display: none;
        }

        .controlled-checkbox {
          @include sticky-cell-position;

          input {
            display: none;
          }
        }

        .controlled-checkbox--is-checked {
          input {
            display: inline-block !important;
          }

          .controlled-checkbox__label-content {
            display: none !important;
          }
        }

        .controlled-checkbox &:first-child td[role='cell'] {
          border-top: none;
        }

        &:last-child td[role='cell'] {
          border-bottom: none;
        }

        &:hover,
        &:focus-within {
          background: $color-gray-6;

          .sticky-cell {
            background-color: $color-gray-6;
          }

          .editable-cell > .dropdown {
            background: transparent;
          }

          .editable-cell > .editable-percent-input {
            background: transparent;
          }

          .controlled-checkbox {
            input {
              display: inline-block;
              outline: none;
            }

            &__label-content {
              display: none;
            }
          }

          .td__off-hover {
            display: none;
          }

          .td__on-hover {
            display: block;
          }

          // show dropdown chevron on row hover
          .editable-cell--base .TrekDropDown-root .TrekDropDown-formControl > svg {
            display: block;
          }

          // show pencil icon on row hover
          .editable-cell--base .editable-cell__icon--base {
            display: block;
            @include filter-gray-2;
          }

          .editable-cell--disabled .editable-date--disabled {
            background: none;
          }

          .editable-cell--base:focus-within {
            box-shadow: none;
          }
        }

        &.trafficking-table__row {
          td.not-editable:last-of-type {
            overflow: hidden;
          }

          td.not-editable.selected:focus-within {
            box-shadow: none;
          }

          &--checked {
            background-color: $color-blue-6;

            .trafficking-table__cell {
              background-color: $color-blue-6;
            }

            .editable-cell > .dropdown {
              background-color: transparent;
            }

            .sticky-cell {
              background-color: $color-blue-6;
            }

            .editable-cell > .editable-percent-input {
              background-color: transparent;
            }

            &:hover,
            &:focus-within {
              background-color: $color-blue-5;

              .trafficking-table__cell {
                background-color: $color-blue-5;
              }

              .editable-cell > .dropdown {
                background-color: transparent;
              }

              .editable-cell > .editable-percent-input {
                background-color: transparent;
              }

              .sticky-cell {
                background-color: $color-blue-5;
              }
            }
          }
        }
      }
      tr[role='none'] {
        height: 100%;
      }

      tr:last-child {
        border-bottom: none;
      }

      tr td:last-child {
        border-right: 1px solid $color-gray-5;
      }

      td[role='cell'] {
        white-space: nowrap;
        font-size: $font-size-standard;
        line-height: 20px;
        color: $color-gray-1;

        &.not-editable {
          cursor: default;
          padding-left: grid-px(2);
          @include text-overflow-ellipsis;
          // used to show outline on the cell when it or any of its children are in focus
          // EditableCell manage their own outline state
          &.selected:focus-within {
            outline: none;
          }

          .trafficking-table__cell {
            padding: 14px grid-px(2);
            @include text-overflow-ellipsis;
          }

          @include keyboard-focus-only;
        }

        &.price-cell {
          padding-right: grid-px(2);
          padding-left: 0;
          text-align: right;
        }

        &.editable {
          padding: 0;

          &:focus-within {
            box-shadow: 0 0 0 2px $color-blue-base inset;
          }
        }

        &.mstr-cell,
        &.df-cell {
          padding: 0;
        }

        &.sticky-cell {
          @include sticky-cell;
          align-self: stretch;
        }
      }
    }

    .resize-separator {
      position: absolute;
      display: flex;
      justify-content: center;
      top: 0;
      right: 0;
      background-color: transparent;
      height: grid-px(5.625);
      width: grid-px(1);
      cursor: default;

      &__left {
        right: initial;
        left: -2px;
      }

      &__element {
        background-color: $color-gray-5;
        height: grid-px(5.625);
        width: grid-px(0.187);

        &:hover {
          background-color: $color-faux-black;
          width: grid-px(0.5);
        }
      }

      &__drag {
        background-color: $color-faux-black;
        height: grid-px(5.625);
        width: grid-px(0.5);
      }
    }
  }

  &__header-cell {
    display: flex;
    height: 100%;
    width: 100%;
    padding: grid-px() grid-px(2) 0 grid-px(2);

    .sort-arrow {
      visibility: hidden;
    }

    &:hover .sort-arrow {
      visibility: visible;
    }
  }

  &__sequence-view {
    .sort-arrow {
      path {
        opacity: 0.3;
      }
    }
  }

  &__header-cell-sorted {
    position: relative;
    display: flex;
    height: 100%;
    padding: grid-px() grid-px(2) 0 grid-px(2);
  }

  .editable-cell-modal__wrapper {
    z-index: $trafficking-table-editable-cell-modal-z-index;
  }

  .create-and-edit-cell,
  .delete-cell {
    display: flex;
    justify-content: center;

    &__button {
      @include table-outlined-button;
    }
  }

  .create-and-edit-cell {
    margin-left: -(grid-px(2));
  }

  &--right-shadow:after {
    right: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  }

  &--left-shadow:before {
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }

  &--sticky-header {
    .trafficking-table__table:after {
      // remove bottom border if header is sticky (or table is scrolling) to avoid
      // double bottom borders
      content: none;
    }

    thead tr {
      // add drop shadow to sticky headers
      filter: $sticky-filter-drop-shadow;
    }
  }

  &__loader {
    width: 100%;

    & img {
      display: block;
      margin: grid-px() auto;
      height: grid-px(4);
    }
  }

  &__info-icon {
    vertical-align: middle;
    width: grid-px(1.5);
    height: grid-px(1.5);
    margin-left: grid-px(0.5);
    cursor: pointer;

    path {
      fill: $color-gray-2;
    }
  }

  &__dragging-element {
    position: fixed;
    pointer-events: none;
    height: 30px;
    white-space: nowrap;
    border: 1px solid black;
    background: $color-gray-5;
    color: black;
    padding: grid-px(0.7);
    border-radius: 1px;
    z-index: 1000000;
    transform: translate(-90%, -50%);
  }
}
