@import '../../../styles/index.scss';

.creative-rejection-reasons-modal {
  &__id-text {
    color: $color-gray-1;
    font-size: grid-px(1.75);
    font-weight: $title-font-weight;
    line-height: grid-px(3);
  }

  &__accent-text {
    font-weight: $title-font-weight;
  }

  &__clipboard-button {
    width: 30px;
    height: 28px;

    a {
      margin: 0;
      padding: 0;
      width: 100%;
      background-color: transparent;
      border: 1px solid $color-black-1;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $color-black-1;

        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }

    svg {
      width: 18px;

      path {
        fill: $color-black;
      }
    }
  }

  &__modal-title-wrapper {
    display: flex;
  }

  &__modal-title {
    margin-right: grid-px(0.5);
  }

  &__clipboard-wrapper {
    display: flex;
    align-items: center;
  }
}
