@import '../styles/index.scss';

$dropdown-width: 300px;

.table-filter-bar {
  display: flex;
  flex-direction: column;

  &__filter-bar-wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: grid-px(2.5);
    padding-bottom: grid-px(3.5);
    font-size: $font-size-small;

    .shareable-url-button {
      margin: 0;
      padding: grid-px(1.875) grid-px(3.25);
    }

    .dropdown {
      width: grid-px(25);

      label.TrekLabel-root {
        margin: grid-px(0.5) 0;

        > span {
          text-transform: capitalize;
          color: $color-gray-1;
          letter-spacing: 0.1px;
          font-weight: 500;
        }
      }
    }

    .trek-dropdown {
      // we want to manually control the Trek Dropdown's state, but aren't able to via props
      // so manually preventing click actions via css instead
      pointer-events: none;
    }

    .filter__menu {
      z-index: $trafficking-filter-menu-z-index;
      border-radius: 0;
      box-shadow: $raised-shadow-standard;
    }

    .filter__menu-list {
      padding: 0 grid-px();
    }

    .filter__group-heading {
      margin: 0;
    }

    .filter__group {
      padding: 0;

      &:not(:first-child) {
        border-top: 1px solid $color-gray-1;
      }
    }

    .filter__option {
      &--is-focused {
        background-color: unset;

        &:hover {
          background-color: $color-blue-6;
        }
      }

      &--is-selected {
        background-color: $color-blue-6;
        color: unset;
      }
    }

    .filter__control {
      margin: grid-px();
      border-radius: $border-radius-standard;
      border: 1px solid $color-gray-4;
    }

    .filter__value-container {
      padding-left: grid-px(4);
    }

    .icon-search {
      position: absolute;
      left: grid-px();
      height: grid-px(2.5);
    }

    &__container {
      position: absolute;
      width: $dropdown-width;
      box-shadow: $raised-shadow-standard;
    }
  }

  &--chips-with-space {
    margin-bottom: grid-px(3.125);
  }

  &--chips-with-no-space {
    margin-bottom: 0;
  }

  .toggle-switch {
    &__toggler {
      &--on {
        .ant-switch {
          background: $color-black;
        }
      }

      &--off {
        .ant-switch {
          background: rgb(0, 0, 0, 0.25);
        }
      }
    }
  }
}

li.table-filter-bar__dropdown-child.TrekDropDownItem-root {
  display: none;
}
