@import '../../../../../../styles/index.scss';

.selectable-ad-form-section-wrapper {
  margin-top: grid-px(4);

  &__checkbox-container {
    display: flex;
    cursor: pointer;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
  }

  &__error {
    color: $color-red-input-error;
    width: grid-px(89)
  }
}
