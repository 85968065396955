@import '../../../../../../../../styles/index.scss';

.duplicated-ad-data {
  display: flex;
  &:not(:last-child) {
    margin-bottom: grid-px(3);
  }

  &__line-item-input {
    margin-bottom: grid-px(1.875);
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
  }

  &__input-wrapper:not(:last-child) {
    margin-right: grid-px(8);
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: grid-px();
    font-size: 12px;

    h3 {
      font-size: grid-px(1.5);
      line-height: grid-px(1.5);
      margin: 0;
      text-transform: uppercase;
    }
  }

  &__line-item-label h3 {
    color: $color-grey-light;
  }
}
