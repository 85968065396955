@import "../../../../../../../styles/index.scss";

.ad-sections-form {
  position: relative;

  &__preview-wrapper {
    padding: grid-px(1);
  }

  &__error-wrapper {
    margin: grid-px(3);
  }

  &__error {
    position: relative;
    top: auto;
    left: auto;

    .alert {
      &__content {
        margin: 0;
      }

      &__close-btn {
        display: none;
      }
    }
  }

  &__form {
    display: flex;
    flex-wrap: nowrap;
  }

  .side-nav {
    margin-left: grid-px();
  }

  .ad-preview-form {
    .card {
      max-width: grid-px(89);
    }

    .frequency-cap,
    .daypart-tabs {
      nav {
        &::after {
          display: none;
        }

        .TrekTabNavigationTab {
          border-radius: grid-px(0.5);
          font-weight: 500;
          background: none;
          border: none;
          border-bottom: none;
        }

        @include segmented-control-nav;
      }
    }
  }
}
