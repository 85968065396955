@import '../../../../../../styles/index.scss';

.vast-preview-input {
  &__custom-input-label {
    display: block;
    margin: grid-px(0.5) 0 grid-px();
    font-size: grid-px(1.5);
    font-weight: 600;
    line-height: grid-px(1.5);
    letter-spacing: grid-px(0.125);
    text-transform: uppercase;
  }

  textarea {
    width: 100%;
    border-radius: grid-px(0.5);
    border: grid-px(0.125) solid $color-gray-3;
    color: $color-faux-black;
    line-height: 1.5;
    padding: grid-px(1.1875) grid-px(3.75) grid-px(1.1875) grid-px(1.5);
    resize: none;
    word-break: break-all;

    &:disabled {
      background-color: $color-gray-5;
    }
  }
}
