@import '../../../../../../styles/index.scss';

.multiple-duplicate-ad-stepper-header {
  width: 100%;
  height: 75px;
  display: flex;
  padding: grid-px(2) grid-px(3.1) grid-px(2) grid-px(2.5);
  align-items: center;
  border-bottom: 1px solid $color-gray-3;
  background: $color-white;

  &__arrow {
    padding-right: grid-px(1.5);
    border-right: 1px solid $color-grey-light;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: grid-px(2);

    svg {
      cursor: pointer;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__buttons-container {
    margin-left: auto;
    display: flex;
  }

  &__close {
    min-width: 167px;
    background: $color-gray-5 !important;
    color: $color-gray-1 !important;
    border: 0 !important;

    span {
      text-transform: capitalize;
    }

    &:hover {
      background: $color-gray-4 !important;
    }
  }

  &__next-step {
    min-width: 150px;
    text-transform: capitalize;

    span {
      text-transform: capitalize;
    }
  }
}
