@import './fonts.scss';
@import './colors.scss';

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, #root, #app {
  height: 100%;
}

#app {
  height: 100%;
  width: auto;
  min-width: min-content;
  display: flex;
  flex-direction: column;
}

code {
  font-family: $font-code;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance:textfield;
}
