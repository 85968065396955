@import '../styles/index.scss';

$deselected-tab-background: $color-gray-5;

// Added, since trafficking page should never be allowed to scroll below table
// And nothing is ever below the table, so overflow y should be hidden
body {
  overflow-y: hidden;
}

.trafficking-page {
  background-color: $page-background;
  box-sizing: border-box;
  padding: $trafficking-page-padding-top $trafficking-page-padding-sides $trafficking-page-padding-bottom;
  position: relative;
  width: 100vw;
  overflow-y: auto;

  &__trafficker {
    .TrekTabNavigation-root {
      position: relative;
      // this forces the tab nav to overlap with the currently selected tab content
      transform: translateY(1px);
      min-width: $trafficking-tab-nav-width;
      z-index: $trafficking-tab-nav-z-index;

      .TrekTabNavigationTab-root {
        background-color: $deselected-tab-background;
        border: 1px solid $color-gray-3;
        border-bottom: none;
        box-sizing: border-box;
        color: $color-gray-2;
        font-size: $font-size-standard;
        font-weight: 600;
        height: $font-size-standard + (2 * $trafficking-tab-vertical-padding);
        line-height: 1;
        margin: 0 -1px 0 0;
        padding: $trafficking-tab-vertical-padding $trafficking-tab-horizontal-padding;
        text-align: left;
        min-width: $trafficking-tab-width;

        &:hover {
          color: $color-gray-1;
        }

        // bottom border for tab
        &:after {
          border-bottom: 1px solid $color-gray-3;
          box-shadow: $raised-shadow-standard;
        }

        .rows-count {
          font-weight: normal;
          margin-right: grid-px(1);
        }
      }

      .TrekTabNavigationTab-selected {
        background-color: $white-background;
        box-shadow: $box-shadow-dark;
        color: $color-faux-black;
        z-index: $trafficking-selected-tab-z-index;

        // remove bottom border for selected tab
        &::after {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  &__tab-navigation-wrapper {
    position: relative;

    // styles for currently selected tab content wrapper
    > div.trafficking-page__table-content {
      background-color: $color-white;
      border-left: 1px solid $color-gray-3;
      border-right: 1px solid $color-gray-3;
      border-bottom: 1px solid $color-gray-3;
      display: flex;
      flex-direction: column;
      height: $trafficking-table-content-height;
      overflow: hidden;
      position: relative;
      width: 100%;

      // border under (z-index) tab navigation and above (vertically) currently selected tab content
      &:before {
        border-bottom: 1px solid $color-gray-3;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      //delivery forensics cell
      .delivery-forensics-link {
        border: 1px solid $color-blue-base;
        border-radius: 4px;
        width: grid-px(3);
        height: grid-px(3);
        display: grid;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 1px solid $color-blue-dark;

          img {
            filter: invert(32%) sepia(96%) saturate(5349%) hue-rotate(207deg) brightness(84%) contrast(85%);
          }
        }
      }
    }
  }

  &__tooltip {
    text-align: left !important;
    border-radius: grid-px(1) !important;
    margin-top: 0 !important;
    top: grid-px(5) !important;
    left: grid-px(14.175) !important;
    right: grid-px(-8) !important;

    &:after {
      left: grid-px(2.5) !important;
    }
  }

  &__drag-element {
    position: fixed;
    top: 0;
    z-index: -10;
    width: 22px;
  }

  .drawer {
    top: $header-height;
  }
}
