@import "../../../styles/index.scss";

.ad-details {
  &__field-input {
    margin-bottom: grid-px(3.4);
    max-width: 50%;
  }

  ul {
    padding-left: grid-px(2.5);
  }

  li {
    font-size: grid-px(2);
    font-weight: bold;
    line-height: grid-px(3);
  }

  span {
    font-weight: normal;
  }
}
