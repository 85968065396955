@import '../../../styles//index.scss';

.header-icon-tooltip {
  &__icon-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__tooltip {
    max-width: grid-px(35);
    font-weight: 400;
    opacity: 1 !important;
    padding: grid-px(2.3) grid-px() !important;
    text-align: center;
  }
}
