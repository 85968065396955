@import "../../styles/index.scss";

.status-container {
  &__activator > * {
    cursor: pointer;
  }

  &__asset-name {
    color: $color-gray-2;
    font-size: grid-px(2.25);
    font-weight: $title-font-weight;
    line-height: grid-px(3);
    padding: grid-px(1) 0 0;
  }

  &__modal {
    .ant-modal-title {
      font-size: grid-px(2.75);
      line-height: grid-px(3.75);
      font-weight: $title-font-weight - 100;
      color: $color-title-gray;
    }

    .ant-modal-close-x {
      svg {
        width: $page-header-font-size;
        height: $page-header-font-size;
      }
    }
    .ant-modal-header {
      border-bottom: none;
    }

    .ant-modal-footer {
      z-index: 1;
      text-align: center;
      border-top: none;
      box-shadow:
        0px 3px 5px rgba(0, 0, 0, 0.2),
        0px 5px 8px rgba(0, 0, 0, 0.14),
        0px 1px 14px rgba(0, 0, 0, 0.12);
    }

    .ant-modal {
      &-close-x {
        svg {
          width: $page-header-font-size;
          height: $page-header-font-size;
        }
      }

      &-header {
        border-bottom: none;
      }

      &-body {
        max-height: 260px;
        overflow-y: scroll;
        scrollbar-width: none;
        padding-top: grid-px(0.25);
        &::-webkit-scrollbar {
          display: none;
        }

        .toggle-section {
          .toggle-section__title {
            color: $color-gray-1;
            font-size: grid-px(2);
            font-weight: $title-font-weight;
          }
          .toggle-section__title-wrapper {
            background: none;
            border: none;
            padding-left: 0;
            padding-bottom: grid-px();
            &:hover {
              background: none;
            }
            svg {
              height: 18px;
            }
          }
        }

        .toggle-section > div:not(:first-child) {
          padding-left: grid-px(2);
        }

        .toggle-section__title-wrapper:hover {
          background-color: $white-background;
        }
      }

      &-footer {
        z-index: 1;
        text-align: center;
        border-top: none;
        box-shadow:
          0px 3px 5px rgba(0, 0, 0, 0.2),
          0px 5px 8px rgba(0, 0, 0, 0.14),
          0px 1px 14px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
