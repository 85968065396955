@import '../../../styles/index.scss';

.targeting-view-button {
  border: 1px solid #B8C7D0;
  border-radius: 5px;
  color: #3611C8;
  display: flex;
  overflow: hidden;
  width: fit-content;

  &__table-view,
  &__text-view {
    align-items: center;
    border: none;
    cursor: pointer;
    font-family: InspireTWDC, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    gap: 4px;
    height: 32px;
    justify-content: center;
    line-height: 16px;
    padding: 0px 16px;
  }

  &__table-view {
    border-right: 1px solid #B8C7D0;
  }

  &__table-view.active,
  &__text-view.active {
    background-color: #E6E6FF;
  }

  &__table-view.inactive,
  &__text-view.inactive {
    background-color: #FFFFFF;
  }
}