@import "../../../../../styles/index.scss";

.rejection-message {
  display: flex;

  &__error-svg {
    margin-right: grid-px();
    align-self: center;

    path {
      fill: $color-red-input-error;
    }
  }

  &__rejection-reason {
    font-weight: 600;
    color: $color-black !important;
    text-decoration: none !important;
    cursor: default !important;
  }

  .status-container {
    display: inline;

    &__activator {
      display: inline;
    }
  }
}
