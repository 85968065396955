@import "../../../../../../styles/index.scss";

.set-ad-sections-step {
  &__title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: grid-px();
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: grid-px();
  }

  &__error {
    position: relative;
    top: auto;
    left: auto;

    .alert{
      &__content {
      margin: 0;
      }

      &__close-btn {
        display: none;
      }
    }
  }
}
