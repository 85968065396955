@import "../../../../../../../styles/index.scss";

.apply-to-all-section {
  padding-top: grid-px(0.5);

  &__info-wrapper {
    margin: 0 grid-px(2);
  }

  &__text {
    color: $color-black;
  }

  &__subtext {
    margin-left: grid-px(0.5);
    color: $color-gray-2;
  }

  &__highlighted-text {
    margin-left: grid-px(0.5);
    color: $color-red-input-error;
  }

  &__button {
    margin-left: 0 !important;
  }
}
