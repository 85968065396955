@import '../../../../../../styles/index.scss';

.filter-chip {
  margin-right: grid-px(1);

  &__chip {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: $color-gray-4;
    padding: grid-px(1.25) grid-px();
    cursor: pointer;

    span {
      line-height: 12px;
      white-space: nowrap;

      margin-left: grid-px(0.5);
    }
  }

  &__label {
    margin-right: grid-px(0.5);
  }

  &__values {
    display: flex;
    flex-wrap: wrap;

    span {
      margin-bottom: 0;
      overflow-wrap: break-word;
    }
  }

  &__close-icon  {
    margin-left: grid-px(1.5);
    min-width: 12px;
    min-height: 12px;
  }
}
