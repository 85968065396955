@import '../../../../../styles/index.scss';

.ad-preview-form {
  flex-grow: 1;

  display: flex;
  justify-content: center;

  background-color: $page-background;

  border: $light-border;
  border-left: 0;

  height: grid-px(65);
  overflow-y: auto;

  &__content > :first-child {
    padding-top: grid-px(4);
  }

  &__content > :last-child {
    padding-bottom: grid-px(4);
  }
}
