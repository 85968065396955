@import "../../../styles/index.scss";

.asset-rejection-reasons-modal-container {
  display: block;
  width: 100%;
  &__activator > * {
    cursor: pointer;
  }
  &__error-svg {
    padding-right: grid-px();
    vertical-align: text-bottom;

    path {
      fill: $color-red-input-error;
    }
  }

  &__text {
    color: $color-blue-base;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    text-transform: none;
    font-size: 10px;
  }
}

.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  opacity: 1; // makes visible in storybook
}

.asset-rejection-reasons-modal {
  z-index: 0;

  &__issue {
    padding-bottom: grid-px();
  }

  &__asset-name {
    color: $color-gray-2;
    font-size: grid-px(2.25);
    font-weight: $title-font-weight;
    line-height: grid-px(3);
    padding: grid-px(1) 0 0;
  }

  &__issue-description {
    font-size: $font-size-big;
    line-height: $page-header-font-size;
    padding-bottom: grid-px(2.5);
    font-weight: $font-weight-bold;
  }

  &__issue-status {
    font-size: $font-size-small;
    line-height: $page-header-font-size;
    padding-bottom: grid-px(1);
  }

  &__comment {
    display: flex;
    align-items: center; // Ensure vertical alignment of all elements
    gap: grid-px(2); // Increase spacing between date, divider, and text
    padding: grid-px(1) 0;
  }

  &__comment-date {
    font-weight: $font-weight-bold; // Make the date bold
    font-size: $font-size-small;
  }

  &__comment-divider {
    width: 1px;
    background-color: $color-gray-1;
    height: auto; // Automatically adjust to the content height
    margin: 0 grid-px(1); // Add space between the divider and adjacent elements
  }

  &__comment-text {
    flex: 1;
    word-wrap: break-word; // Ensure long comments wrap within the container
    text-align: left; // Align comment text to the left
  }
}
