@import '../../../../../../../styles/index.scss';

.set-ad-name-wrapper {
  margin: grid-px(3);
  overflow: auto;
  height: 550px;

  &__title {
    font-size: grid-px(2);
    font-weight: normal;
    line-height: grid-px(3);
  }
}
