@import '../../styles/index.scss';
@import '../Form/Form.scss';

.entity-search {
  margin: $form-element-margin;
  position: relative; // allow loading spinner to be positioned inside search bar

  // hide submit button
  .TrekSearchField-submitButton {
    display: none;
  }

  div.TrekSearchResults-root {
    position: relative;
    max-height: grid-px(32);
    overflow: auto;
    padding: 0;
    padding-inline-start: 1px;
    z-index: 5;

    li {
      &::after {
        border-bottom: 2px dotted $color-gray-4;
      }

      &:last-child:after {
        border-bottom: none;
      }
    }
  }

  .TrekSearchResultsItem-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: grid-px(3.75);
  }

  & .creative-name {
    flex: 1;
    font-size: grid-px(1.75);
    word-break: break-all;
  }

  & .creative-id {
    color: $color-grey-light;
    font-size: grid-px(1.5);
    font-weight: 400;
    line-height: grid-px(2.25);
  }

  & .search-term {
    background-color: transparent;
    font-weight: bold;
    color: $text-black;
  }

  & .max-results-prompt,
  & .no-results-prompt {
    cursor: default;

    &:hover {
      background-color: $color-white;
    }

    p {
      color: $color-gray-2;
    }
  }

  &__loading-spinner {
    width: grid-px(3);
    position: absolute;
    top: grid-px(4);
    right: grid-px(0.75);
  }

  &__loader {
    display: flex;
  }
}
