@import '../../../../../../../styles/index.scss';

.multiple-edit-confirm-warning {
  display: flex;
  margin-top: grid-px(2);
  align-items: center;

  &__wrapper-error-text {
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    color: $color-yellow-dark;
  }

  &__warning-icon {
    margin-right: grid-px(2);
  }
}
