@import "../../../../../styles/index.scss";

.asset-sub-tab {
  padding-top: grid-px(4);

  nav {
    margin: 0 grid-px(4);
  }

  ::after {
    display: none;
  }

  .TrekTabNavigation-root {
    padding: grid-px(0.5);
  }

  .TrekTabNavigationTab-root {
    display: block;
    background-color: $color-gray-5;
    box-sizing: border-box;
    border: none;
    color: $color-gray-2;
    font-size: $font-size-standard;
    font-weight: 600;
    line-height: 1;
    padding: grid-px();
    text-align: left;
    width: 100%;

    &:hover {
      color: $color-gray-1;
    }

    .rows-count {
      font-weight: normal;
      margin-right: grid-px(1);
    }
  }

  .TrekTabNavigationTab.TrekTabNavigationTab-root {
    border-radius: grid-px(0.5);
  }

  &__tab-child-wrapper {
    padding: grid-px(4);
  }

  &__segmented-control {
    @include segmented-control-nav;
    margin-bottom: grid-px(2.5);
    overflow: auto;
    width: auto;
    justify-content: unset;

    &--auto {
      width: auto;
    }

    &--fit {
      width: fit-content;
    }
  }

  .TrekTabNavigationTab-selected {
    background-color: $color-white;
    box-shadow: $box-shadow-segmented-control !important;
    color: $color-faux-black;
  }

  &__empty-message {
    width: 100%;
    text-align: center;
    padding: grid-px(4);
    font-size: 18px;
    color: $color-gray-2;
  }
}
