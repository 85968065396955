@import '../../styles/index.scss';

.asset-preview {
  // position status pill
  position: relative;

  img,
  video {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  video {
    background-color: black;
  }

  video::-webkit-media-controls {
    visibility: hidden;
  }

  video::-webkit-media-controls-enclosure {
    visibility: visible;
  }

  &__video-container {
    position: relative;
  }

  &__loading-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;

    span {
      img {
        display: block;
        margin: grid-px(8) auto;
        height: grid-px(8);
      }
    }
  }

  &__image-container {
    span {
      img {
        display: block;
        margin: grid-px(5) auto;
        height: grid-px(5);
      }
    }
  }

  &__review-status-pill {
    position: absolute;
    z-index: 2;
    top: grid-px(1.5);
    left: grid-px(1.5);
  }

  &__review-status-content {
    display: flex;

    .asset-rejection-reasons-modal-container {
      &__text {
        margin-left: grid-px(0.5);
        vertical-align: baseline;
      }
    }
  }

  & .validation-error {
    border: 1px dashed $color-red-base;
  }

  &__asset-name {
    display: inline-block;
    font-weight: bold;
    margin-bottom: grid-px(0.625);
  }

  &__text-asset-container {
    textarea {
      width: 100%;
      height: grid-px(20);
      overflow-y: auto;
      border-radius: grid-px(0.5);
      border: grid-px(0.125) solid $color-gray-3;
      color: $color-faux-black;
      line-height: 1.5;
      padding: grid-px(1.2) grid-px(1.8);
      resize: none;
      word-break: break-all;

      &:disabled {
        background-color: transparent;
        pointer-events: all;
      }
    }
  }
}
